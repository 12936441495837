import React, { createContext, useState } from "react"
import sublinks from "../constants/links"
import callToActionLinks from "../constants/CallToActionLinks"

const GatsbyContext = createContext()

// Provider, Consumer

const GatsbyProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [links, setLinks] = useState(sublinks)
  const [extendLinks, setExtendLinksk] = useState(callToActionLinks)

  const toggleSidebar = () => {
    // console.log("Click")

    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <GatsbyContext.Provider
      value={{ isSidebarOpen, links, extendLinks, toggleSidebar }}
    >
      {children}
    </GatsbyContext.Provider>
  )
}

export { GatsbyContext, GatsbyProvider }
