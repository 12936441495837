import React from "react"

export default [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "about",
    url: "/aboutUs/",
  },
  {
    id: 3,
    text: "menus",
    url: "/menus/",
  },
  {
    id: 4,
    text: "contact",
    url: "/contact/",
  },
]
