import React from "react"
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa"

export default [
  {
    id: 1,
    url: "",
    icon: <FaPhoneAlt />,
    href: "tel:802-225-6010",
  },
  {
    id: 2,
    url: "https://goo.gl/maps/asW7pfrYX2khsrBY6",
    icon: <FaMapMarkerAlt />,
    href: "",
  },
]
